import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { Children, cloneElement } from 'react';
import Button from '@mui/material/Button';
import TransactionIcon from '@mui/icons-material/ShoppingCart';
import PaymentcardIcon from '@mui/icons-material/CreditCard';
import { Link } from 'react-router-dom';
import { BooleanField, BooleanInput, ChipField, Datagrid, EditButton, EmailField, ExportButton, FilterButton, List, ReferenceField, ShowButton, TextField, TextInput, TopToolbar, useRecordContext } from 'react-admin';

const useUserListActionToolbarStyles = makeStyles({
  toolbar: {
    alignItems: 'center',
    display: 'flex',
    marginTop: -1,
    marginBottom: -1,
  },
});

const UserListActions = () => (
  <TopToolbar>
    <FilterButton />
    <ExportButton maxResults={50000} />
  </TopToolbar>
);

const UserListActionToolbar = ({ children, ...props }) => {
  const classes = useUserListActionToolbarStyles();
  return (
    <div className={classes.toolbar}>
      {
        Children.map(children, button => cloneElement(button, props))
      }
    </div>
  );
};

const LinkToRelatedTransactions = () => {
  const record = useRecordContext();
  return record ? (
    <Button
      color="secondary"
      variant="outlined"
      component={Link}
      startIcon={<TransactionIcon />}
      to={{
        pathname: '/transactions',
        search: `filter=${JSON.stringify({ user_id: record.id })}`,
      }}
    >
      Transactions
    </Button>
  ) : null;
};

const LinkToRelatedPaymentCards = () => {
  const record = useRecordContext();
  return record ? (
    <Button
      color="secondary"
      variant="outlined"
      component={Link}
      startIcon={<PaymentcardIcon />}
      to={{
        pathname: '/paymentcards',
        search: `filter=${JSON.stringify({ user_id: record.id })}`,
      }}
    >
      Payment Cards
    </Button>
  ) : null;
};

const userFilters = [
  <TextInput source="firstName" label="Search First name" />,
  <TextInput source="lastName" label="Search Last name" />,
  <TextInput source="email" label="Search Email" />,
  <TextInput source="phone" label="Search Phone" />,
  <TextInput source="everydayRewardsCard" label="Search Everyday Rewards card" />,
  <BooleanInput source="isActive" label="Search Is active" />,
];

const UserList = () => (
  <List actions={<UserListActions />} filters={userFilters}>
    <Datagrid>
      <TextField label="ID" source="id" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="phone" />
      <EmailField source="email" />
      <BooleanField looseValue source="isActive" />
      <ReferenceField label="Gift card" source="giftcard_id" reference="giftcards">
        <ChipField source="tranxactorCard" />
      </ReferenceField>
      <UserListActionToolbar>
        <EditButton />
        <ShowButton />
        <LinkToRelatedTransactions />
        <LinkToRelatedPaymentCards />
      </UserListActionToolbar>
    </Datagrid>
  </List>
);

export default UserList;
