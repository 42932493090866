import simpleRestProvider from 'ra-data-simple-rest';
import { fetchUtils } from 'ra-core';
import { combineDataProviders } from 'react-admin';
import S3 from 'react-aws-s3';

window.Buffer = window.Buffer || require('buffer').Buffer;

const s3config = {
  bucketName: 'gas-app-assets',
  dirName: 'promotions',
  region: 'ap-southeast-2',
  accessKeyId: process.env.REACT_APP_AWS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET,
};

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  options.headers.set('X-Api-Key', process.env.REACT_APP_API_KEY);
  options.headers.set('Authorization', window.localStorage.getItem('_gasAccessToken'));
  return fetchUtils.fetchJson(url, options);
};

const API_URL = process.env.REACT_APP_API_URL;
const dataProvider1 = simpleRestProvider(API_URL, httpClient);

const API_URL2 = process.env.REACT_APP_API_URL2;
const dataProvider2 = simpleRestProvider(API_URL2, httpClient);

const API_URL3 = process.env.REACT_APP_API_URL3;
const dataProvider3 = simpleRestProvider(API_URL3, httpClient);

const dataProvider = combineDataProviders(resource => {
  switch (resource) {
    case 'maintenancemodes':
    case 'referrals':
    case 'frequentlyaskedquestions':
      return dataProvider3;
    case 'giftcards':
    case 'giftcardtransactions':
    case 'campaigncodes':
    case 'campaigncoderedemptions':
    case 'referralamounts':
    case 'tranxactors':
      return dataProvider2;
    default:
      return dataProvider1;
    // throw new Error(`Unknown resource: ${resource}`);
  }
});

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
// const convertFileToBase64 = file =>
//   new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.onload = () => resolve(reader.result);
//     reader.onerror = reject;

//     reader.readAsDataURL(file.rawFile);
//   });

export const myDataProvider = {
  ...dataProvider,
  sitesUpdater: async () => {
    return await httpClient(`${process.env.REACT_APP_API_URL3}/gas/sites/siteintegration`);
  },
  sendTransactionEmail: async id => {
    return await httpClient(`${process.env.REACT_APP_API_URL3}/sendtransactionemailforadmin?id=${id}`);
  },
  getGiftCardBalance: async id => {
    return await httpClient(`${process.env.REACT_APP_API_URL2}/giftcards/balance/${id}`);
  },
  create: (resource, params) => {
    if (resource === 'promotionalofferscalendar') {
      resource = 'promotionaloffers';
      return dataProvider.update(resource, params);
    }

    if (resource !== 'promotionaloffers') {
      // fallback to the default implementation
      return dataProvider.create(resource, params);
    }

    const newPictures = [params.data.imageUrl].filter(p => p.rawFile instanceof File);
    if (newPictures.length > 0) {
      const ReactS3Client = new S3(s3config);
      return ReactS3Client.uploadFile(newPictures[0].rawFile, newPictures[0].rawFile.name, s3config)
        .then(data => {
          return dataProvider.create(resource, {
            data: {
              ...params.data,
              imageUrl: data.location,
            },
          });
        })
        .catch(err => console.error(err));
    } else {
      return dataProvider.create(resource, params);
    }
  },
  getOne: (resource, params) => {
    if (resource === 'promotionalofferscalendar') {
      resource = 'promotionaloffers';
    }
    if (resource === 'sites') {
      params = { ...params, meta: { isVip: true } };
    }
    return dataProvider.getOne(resource, params);
  },
  getList: (resource, params) => {
    if (resource === 'promotionalofferscalendar') {
      resource = 'promotionaloffers';
    }
    if (resource === 'sites') {
      params = { ...params, meta: { isVip: "true" } };
    }
    return dataProvider.getList(resource, params);
  },
  update: (resource, params) => {
    if (resource === 'promotionalofferscalendar') {
      resource = 'promotionaloffers';
      // console.log('update params', params);
      return dataProvider.update(resource, params);
    }

    if (resource !== 'promotionaloffers') {
      // fallback to the default implementation
      return dataProvider.update(resource, params);
    }

    /**
     * For promotional offers update only, convert uploaded image in base 64 and attach it to
     * the `picture` sent property, with `src` and `title` attributes.
     */

    // Freshly dropped pictures are File objects and must be converted to base64 strings
    const newPictures = [params.data.imageUrl].filter(p => p.rawFile instanceof File);
    // const formerPictures = [params.data.imageUrl].filter(
    //   p => !(p.rawFile instanceof File)
    // );

    // console.log('newPictures', newPictures);

    if (newPictures.length > 0) {
      const ReactS3Client = new S3(s3config);
      return ReactS3Client.uploadFile(newPictures[0].rawFile, newPictures[0].rawFile.name, s3config)
        .then(data => {
          // console.log(data)
          return dataProvider.update(resource, {
            data: {
              ...params.data,
              imageUrl: data.location,
            },
          });
        })
        .catch(err => console.error(err));
    } else {
      return dataProvider.update(resource, params);
    }

    // return Promise.all(newPictures.map(convertFileToBase64))
    //   .then(base64Pictures => {
    //     return base64Pictures.map(picture64 => ({
    //       src: picture64,
    //       title: `${params.data.title}`,
    //     }))
    //   })
    //   .then(transformedNewPictures => {
    //     return dataProvider.update(resource, {
    //       data: {
    //         ...params.data,
    //         // imageUrl: [
    //         //   ...transformedNewPictures,
    //         //   ...formerPictures,
    //         // ],
    //         imageUrl: transformedNewPictures[0].src
    //       },
    //     })
    //   });
  },
};
