import * as React from 'react';
import { BooleanField, ChipField, ReferenceArrayField, Show, SimpleShowLayout, SingleFieldList, TextField, useRecordContext } from 'react-admin';

const SiteTitle = () => {
  const record = useRecordContext();
  return (
    <span>Site {record ? `"${record.siteName}"` : ''}</span>
  );
};

const SiteShow = props => (
  <Show {...props} title={<SiteTitle />} mutationOptions={{ meta: { isVip: "true" } }}>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <TextField source="siteName" />
      <TextField source="siteType" />
      <TextField source="latitude" />
      <TextField source="longitude" />
      <TextField source="address" />
      <TextField source="phone" />
      <TextField source="hours" />
      <BooleanField looseValue source="is247" />
      <TextField source="gst" />
      <ReferenceArrayField label="Fuel grades" reference="fuelgrades" source="fuelGrade_id">
        <SingleFieldList>
          <ChipField source="fuelGrade" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField label="Services" reference="services" source="service_id">
        <SingleFieldList>
          <ChipField source="service" />
        </SingleFieldList>
      </ReferenceArrayField>
      <BooleanField looseValue source="canPayInCar" />
      <TextField source="invencoId" />
      <TextField source="windcaveId" />
      <TextField source="tranxactorId" />
      <TextField source="triquestraId" />
      <TextField source="aasfTerminalId" />
      <TextField source="aasfSecret" />
    </SimpleShowLayout>
  </Show>
);

export default SiteShow;
