import * as React from 'react';
import { Create, ReferenceInput, SelectInput, SimpleForm, TextInput } from 'react-admin';

const GiftcardCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="phone" />
      <ReferenceInput label="User" source="user_id" reference="users">
          <SelectInput optionText="email" />
      </ReferenceInput>
      <TextInput source="tranxactorCard" />
      {/* <TextInput source="balance" /> */}
    </SimpleForm>
  </Create>
);

export default GiftcardCreate;
