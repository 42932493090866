import * as React from 'react';
import { BooleanInput, DateTimeInput, Edit, ReferenceArrayInput, SelectArrayInput, SimpleForm, TextInput, useRecordContext } from 'react-admin';
import spacetime from 'spacetime';

const UserTitle = () => {
  const record = useRecordContext();
  return (
    <span>User {record ? `"${record.firstName} ${record.lastName}"` : ''}</span>
  );
};

const dateTimeParser = value => {
  return spacetime(value).unixFmt('yyyy-MM-ddTHH:mm:ss');
};

const dateTimeFormatter = value => {
  return spacetime(value).unixFmt('yyyy-MM-ddTHH:mm:ss');
};

const UserEdit = props => (
  <Edit {...props} title={<UserTitle />}>
    <SimpleForm>
      <TextInput disabled label="ID" source="id" />
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <TextInput source="phone" />
      <TextInput source="email" />
      <DateTimeInput source="dateOfBirth" parse={dateTimeParser} format={dateTimeFormatter} />
      <DateTimeInput source="firstSeen" parse={dateTimeParser} format={dateTimeFormatter} />
      <DateTimeInput source="lastLoggedIn" parse={dateTimeParser} format={dateTimeFormatter} />
      {/* <TextInput label="AA Smartfuel card" source="smartFuelCard" /> */}
      {/* <TextInput label="GAS Instant Discounts card" source="triquestraLoyaltyCard" /> */}
      <TextInput label="Everyday Rewards card" source="everydayRewardsCard" />
      <ReferenceArrayInput source="site_id" reference="sites">
        <SelectArrayInput label="Favourites" optionText="siteName" source="site_id" />
      </ReferenceArrayInput>
      <TextInput source="fcmToken" />
      <TextInput source="cognitoId" />
      <TextInput source="referralCode" />
      <BooleanInput source="isActive" />
      <BooleanInput source="allowTransNotif" />
      <BooleanInput source="allowPromoNotif" />
    </SimpleForm>
  </Edit>
);

export default UserEdit;
