import * as React from 'react';
import { useMutation } from 'react-query';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Edit, ReferenceInput, SelectInput, SimpleForm, TextInput, useDataProvider, useRecordContext } from 'react-admin';

const GiftcardTitle = () => {
  const record = useRecordContext();
  return (
    <span>Gift card {record ? `"${record.tranxactorCard}"` : ''}</span>
  );
};

const giftcardIntegrationStyles = makeStyles({
  label: {
    fontSize: '0.75em',
    marginTop: 0,
    marginBottom: '0.2em',
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: '0.00938em',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  value: {
    marginTop: '0.2em',
    marginBottom: 0,
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: 1.43,
    letterSpacing: '0.01071em',
    color: 'rgba(0, 0, 0, 0.87)',
  },
});

const GetGiftCardBalance = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const classes = giftcardIntegrationStyles();
  const { data, mutate, isLoading } = useMutation(
    ['getGiftCardBalance'],
    () => dataProvider.getGiftCardBalance(record.id),
  );
  const giftCardBalanceLabel = isLoading ? 'Loading...' : 'Get Latest Gift Card Balance';

  return record ? (
    <>
      <p className={classes.label}>Balance</p>
      <Button disabled={isLoading} onClick={() => mutate()} color="secondary" variant="outlined" size="medium">
        {giftCardBalanceLabel}
      </Button>
      <p className={classes.value}>{data?.json?.outcomeCode === "0" ? `$${data?.json?.giftCardBalance}` : ''}</p>
    </>
  ) : null;
};

const GiftcardEdit = props => (
  <Edit {...props} title={<GiftcardTitle />}>
    <SimpleForm>
      <TextInput source="phone" />
      <ReferenceInput label="User" source="user_id" reference="users">
          <SelectInput optionText="email" />
      </ReferenceInput>
      <TextInput source="tranxactorCard" />
      <GetGiftCardBalance />
    </SimpleForm>
  </Edit>
);

export default GiftcardEdit;
